<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-3">
															<div class="vc_column-inner">
																<div class="wpb_wrapper"></div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-9">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'multitasking-a45' }">
																						<em>Up
																						Next: </em> Multitasking <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1636691391154"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-3">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Productivity hacks</h2>
																			<h3>Structure your day</h3>
																			<p>The quest to get more done is something
																				that most of us are after. But you may
																				be surprised to know that the path to
																				greater productivity isn’t always about
																				doing more. But rather, it’s about
																				adopting strategies to be more
																				effective, efficient and focused. That
																				is the key to making the most of your
																				time.</p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-9">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_right   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img class="vc_single_image-img "
																					src="../../assets/images/airbnb/Replacement-for-Productivity-Hacks-Structure-Your-Day2-scaled.jpg"
																					width="700" height="360"
																					alt="Replacement for Productivity Hacks - Structure Your Day2"
																					title="Replacement for Productivity Hacks - Structure Your Day2" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="75" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Hacks-Structure-Day-Know-Your-Rhythms-1.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity-Hacks---Structure-Day---Know-Your-Rhythms" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637356902943">
																		<div class="wpb_wrapper">
																			<h4>Know Your Rhythms</h4>
																			<p>&nbsp;</p>
																			<p>If you want to maximize your
																				productivity, learn when you do what’s
																				best.</p>
																			<p>&nbsp;</p>
																			<p>Are you better at detailed work in the
																				morning? Creative stuff in the
																				afternoon? Schedule tasks according to
																				your natural rhythms.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="50" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Hacks-Structure-Your-Day-Deep-Dive-1.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity-Hacks---Structure-Your-Day---Deep-Dive" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637356919322">
																		<div class="wpb_wrapper">
																			<h4>Deep Dive</h4>
																			<p>&nbsp;</p>
																			<p>Schedule in uninterrupted time on your
																				calendar for deep dive, heads-down work.
																			</p>
																			<p>&nbsp;</p>
																			<p>This means no meetings, emails, calls,
																				texts, Slack messages or anything else
																				that will take you off of your game.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="50" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Hacks-Structure-Day-Embrace-Routines.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity-Hacks---Structure-Day---Embrace-Routines" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637356930798">
																		<div class="wpb_wrapper">
																			<h4>Embrace Routines</h4>
																			<p>&nbsp;</p>
																			<p>One of the best ways to stay productive
																				is to create a routine for yourself.
																				That may include quiet morning time to
																				plan your day, or an end of the day
																				routine to plan your next day.</p>
																			<p>&nbsp;</p>
																			<p>Planning apps may help.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
							<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>Discover more productivity tips</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																	<div class="wpb_wrapper">
																		<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="537"
																						src="../../assets/images/airbnb/Airbnb-Working-From-Office-Everyday-stretches.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Working-From-Office---Everyday-stretches">
																			</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<h4>Increase your energy</h4>
																			<p>How to manage your energy rather than
																				your time.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="364"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Pomodoro"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			<h4>Pomodoro Technique</h4>
																			<p>Manage your time with the Pomodoro
																				Technique.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Sensory-Overload.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Sensory-Overload"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																			<h4>Sensory overload</h4>
																			<p>Check out these hacks to avoid headaches,
																				eye and ear fatigue and more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="2560" height="905"
																						src="../../assets/images/airbnb/Replacement-for-Productivity-Hacks-Structure-Your-Day2-scaled.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Replacement for Productivity Hacks - Structure Your Day2"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																			<h4>Productivity hacks</h4>
																			<p>Productivity hacks to help you crush your
																				work day.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Time-Management.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity--Time-Management"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																			<h4>Time management</h4>
																			<p>Manage your time between work and
																				downtime.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="482"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Enhanced-Focus.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Enhanced-Focus"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																			<h4>Enhanced focus</h4>
																			<p>Get focused and get productive with these
																				focus enhancing tips.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					



					</div>
				</div>
			

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'productivity-hacks-a45',
		mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
		methods: {
			hideModal() {
				// this.$emit('hide');
				document.getElementById("closed").src += "";
			},
		}
	}
</script>